import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import styled from 'styled-components'

const FormStyle = styled.div`
    color: var(--gray-color-2);
    font-family: var(--gothic);
    font-weight: 300;
    margin-bottom: 100px;
    .contact-description {
        margin-top: 40px;
        p {
            font-size: 16px;
            margin-bottom: 25px;
        }
        @media screen and (max-width: 500px) {
            p {
                font-size: 14px;
            }
        }
    }
    form {
        margin: 20px;
        margin-top: 80px;
        .item {
            box-sizing: border-box;
            margin: 30px 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 32px;
            label {
                width: 350px;
            }
            input, textarea {
                width: 100%;
                height: 100%;
                font-family: var(--gothic);
                font-weight: 300;
                border: solid 1px #aaa;
            }
            textarea {
                min-height: 500px;
            }
            &.message {
                align-items: flex-start;
                height: auto;
                label {
                    margin: 10px 0;
                }
            }
        }
        .button-container {
            display: flex;
            justify-content: center;
        }
        @media screen and (max-width: 750px) {
            margin: 0;
            .item {
                flex-direction: column;
                height: 70px;
                align-items: flex-start;
                label {
                    margin: 10px 0;
                }
                font-size: 14px;
            }
        }
        .succeed {
            width: 100%;
            padding: 30px 20px;
            background: #feffe6;
            border: solid 2px #fadb14;
        }
    }
`

const Asterisk = styled.span`
    color: #e65f5f;
`

const ButtonStyle = styled.div`
button {
    min-width: 200px;
    font-family: var(--gothic);
    font-weight: 300;
    font-size: 0.8rem;
    display: inline-block;
    padding: 1em 1.2em;
    text-decoration: none;
    color: #5a5a5a;
    border: solid 1px #aaa;
    border-radius: 1px;
    transition: var(--transition-timing);
    cursor: pointer;
    &:hover {
        background: #fafafa;
        color: #000;
    }
}
`

function ContactForm() {
  const [state, handleSubmit] = useForm("myylzypw");
  return (
    <FormStyle>
        <div className='contact-description'>
            <p>ご質問やご要望は下記フォームよりご連絡ください。</p>
            <p>必要事項をご記入の上、「送信する」をクリックしてください。</p>
            <p><Asterisk>*</Asterisk>は必須項目です。</p>
        </div>
        <form onSubmit={handleSubmit}>
            <div className='item'>
                <label htmlFor="name">氏名<Asterisk>*</Asterisk></label>
                <input
                    id="name"
                    type="name" 
                    name="name"
                />
                <ValidationError 
                    prefix="Name" 
                    field="name"
                    errors={state.errors}
                />
            </div>
            <div className='item'>
                <label htmlFor="email">メールアドレス<Asterisk>*</Asterisk></label>
                <input
                    id="email"
                    type="email" 
                    name="email"
                />
                <ValidationError 
                    prefix="Email" 
                    field="email"
                    errors={state.errors}
                />
            </div>
            <div className='item'>
                <label htmlFor="tel">電話番号<Asterisk>*</Asterisk></label>
                <input
                    id="tel"
                    type="tel" 
                    name="tel"
                />
                <ValidationError 
                    prefix="Tel" 
                    field="tel"
                    errors={state.errors}
                />
            </div>
            <div className='item'>
                <label htmlFor="subject">お問い合わせ件名<Asterisk>*</Asterisk></label>
                <input
                    id="subject"
                    type="subject" 
                    name="subject"
                />
                <ValidationError 
                    prefix="Subject" 
                    field="subject"
                    errors={state.errors}
                />
            </div>
            <div className='item message'>
                <label htmlFor="message">お問い合わせ内容<Asterisk>*</Asterisk></label>
                <textarea
                    id="message"
                    name="message"
                    maxLength="1600"
                />
                <ValidationError 
                    prefix="Message" 
                    field="message"
                    errors={state.errors}
                />
            </div>
            {!state.succeeded && (
                <div className='button-container'>
                <ButtonStyle>
                    <button type="submit" disabled={state.submitting}>
                        送信する
                    </button>
                </ButtonStyle>
                </div>
            )}
            {state.succeeded && (
                <div className='succeed'>正常に送信されました。</div>
            )}
        </form>
    </FormStyle>
  );
}

export default ContactForm