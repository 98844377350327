import React from 'react'
import styled from 'styled-components'

const TitleStyle = styled.div`
width: auto;
font-family: var(--gothic);
display: flex;
align-items: center;
justify-content: flex-start;
margin: 30px 0;
border-bottom: solid 1px #aaa;
.title-title {
    font-size: 24px;
    padding: 20px 0;
    width: auto;
    white-space: nowrap;
}
.title-text {
    font-size: 14px;
    padding: 20px;
}
@media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    .title-title {
        padding: 0;
        font-size: 20px;
    }
    .title-text {
        font-size: 12px;
        padding: 15px 0;
    }
}
`

function Title({title, subtitle, darkMode}) {
    return (
        <TitleStyle style={{borderBottom: darkMode ? 'solid 1px #eee' : 'solid 1px #aaa'}}>
            <h2 className='title-title' style={{color: darkMode ? '#eee' : '#5a5a5a'}}>{title}</h2>
            <p className='title-text' style={{color: darkMode ? '#dadada' : '#aaa'}}>{subtitle}</p>
        </TitleStyle>
    )
}

export default Title
