import React, { useEffect, useState } from 'react'
import Layout from '../components/layout/layout'
import ContactForm from '../components/contact/ContactForm.js'
import PageComponent from '../components/common/PageComponent'
import PageTop from '../components/common/PageTop'
import Title from '../components/common/Title'


const pageComponentStyle = {
    background: 'var(--white-color-1)',
    padding: 0
}
const containerStyle = {
    maxWidth: '1000px',
    margin: '0 auto',
}

function ContactPage() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [width, setWidth] = useState();
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        if (!isLoaded) {
            setIsLoaded(true)
            handleResize();
        }
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <Layout pageTitle="お問い合わせ">
            <PageTop title='CONTACT' />
            <PageComponent styles={pageComponentStyle} containerStyles={containerStyle}>
                <Title title='お問い合わせ' subtitle='製品に関する質問などはこちら' />
                <ContactForm />
            </PageComponent>
        </Layout>
    )
}

export default ContactPage
