import React from 'react'
import styled from 'styled-components'

const PageTopStyle = styled.div`
width: 100%;
height: 300px;
position: relative;
font-family: var(--gothic);
display: flex;
align-items: center;
justify-content: center;
box-sizing: border-box;
.pagetop-title {
    position: absolute !important;
    font-size: 32px;
    letter-spacing: 0.1em;
    color: var(--white-color-1);
    font-weight: 600;
    transform: translateY(40px);
}

background: linear-gradient(60deg, #0a0a0a, #0a0a0a, #0a0a0a, #ee7752, #e73c7e, #23a6d5, #23d5ab, #0a0a0a, #0a0a0a, #0a0a0a);
background-size: 600% 600%;
animation: gradient 25s linear infinite normal;

@media screen and (max-width: 600px) {
    height: 250px;
    background: #0a0a0a;
    animation: none;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	70% {
		background-position: 0% 50%;
	}
	100% {
        background-position: 100% 50%;
	}
}

`

function PageTop({title}) {
    return (
        <PageTopStyle>
            <h2 className='pagetop-title'>{title}</h2>
        </PageTopStyle>
    )
}

export default PageTop
